<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

const props = defineProps<{
  data: {
    image_day: string
    image_night: string
    hero_video?: DirectusFile
    hero_video_mobile?: DirectusFile
    translations: DirectusTranslation<{
      title: string
      intro_text: string
      cta_button_text?: string
      cta_button_link?: string
    }>
  }
}>()

const theme = useCookie<'light' | 'dark'>('home-theme', {
  default: () => 'light',

})

function setTheme(button: 'light' | 'dark') {
  theme.value = button
};

const config = useRuntimeConfig()

const _assetRoot = `${config.public.directus.url}/assets/`

const videoAssetRoot = `${config.public.directus.remote_file_storage}/`

const mustBeLight = computed(() => {
  if (props.data.hero_video)
    return 'dark'
  else
    return theme.value
})

const breakpoints = useBreakpoints(breakpointsTailwind)

const heroVideo = computed(() => {
  if (props.data.hero_video_mobile && breakpoints.smaller('md').value) {
    return props.data.hero_video_mobile
  }
  else {
    return props.data.hero_video
  }
})
</script>

<template>
  <div>
    <BlockBase :is-light="mustBeLight === 'light'" class="bg-starline-secondary duration-1s ease-out block-light:bg-neutral-white">
      <div
        class="relative min-h-screen bg-starline-secondary duration-1s ease-out md:block block-light:bg-neutral-white"
        :class="props.data.hero_video ? '' : 'hidden'"
      >
        <template v-if="!props.data.hero_video">
          <div class="pointer-events-none absolute bottom-0 right-0 w-full">
            <NuxtPicture
              provider="directus"
              :src="props.data.image_day"
              class="h-full w-full bg-bottom object-cover"
              alt=""
              :img-attrs="{
                class: 'h-full w-full object-cover bg-bottom',
              }"
            />
          </div>
          <div
            class="pointer-events-none absolute bottom-0 right-0 w-full transition-opacity duration-1s ease-out"
            :class="theme === 'dark' ? 'opacity-100' : 'opacity-0'"
          >
            <NuxtPicture
              provider="directus"
              :src="props.data.image_night"
              alt=""
              :img-attrs="{
                class: 'h-full w-full bg-bottom object-cover',
              }"
            />
          </div>
        </template>
        <template v-if="props.data.hero_video">
          <div class="pointer-events-none absolute inset-0">
            <video :key="`video-${heroVideo.id}`" disablepictureinpicture loop muted playsinline autoplay class="h-full w-full object-cover">
              <source :src="videoAssetRoot + heroVideo.filename_disk" :type="heroVideo.type">
            </video>
          </div>
          <div class="pointer-events-none absolute inset-0 bg-starline-secondary/60" />
          <div class="hero-gradient pointer-events-none absolute inset-0" />
        </template>

        <Container class="relative pb-20rem pt-14rem">
          <h1
            class="mb-2rem max-w-6xl text-balance text-neutral-white textH1 block-light:text-neutral-black"
          >
            {{ dt(props.data.translations)?.title }}
          </h1>
          <p class="mb-2rem textSubtitle">
            {{ $t('home.every_moment') }}
          </p>
          <div v-if="!data.hero_video" class="flex items-center gap-1rem">
            <BtnIcon :active="theme === 'light'" @click="setTheme('light')">
              <IconSun />
            </BtnIcon>
            <BtnIcon :active="theme === 'dark'" @click="setTheme('dark')">
              <IconMoon />
            </BtnIcon>
          </div>
        </Container>
        <div class="gradient-down absolute inset-x-0 bottom-0 h-8rem" />
      </div>
      <div class="bg-starline-secondary duration-1s ease-out block-light:bg-neutral-white">
        <Container class="relative mb-8 pt-14rem" :class="props.data.hero_video ? 'hidden' : 'md:hidden'">
          <h1
            class="mb-2rem max-w-6xl text-balance text-neutral-white duration-1s textH1 block-light:text-neutral-black"
          >
            {{ dt(props.data.translations)?.title }}
          </h1>
          <p class="mb-2rem textSubtitle">
            {{ $t('home.every_moment') }}
          </p>
          <div v-if="!data.hero_video" class="flex items-center gap-1rem">
            <BtnIcon :active="theme === 'light'" @click="setTheme('light')">
              <IconSun />
            </BtnIcon>
            <BtnIcon :active="theme === 'dark'" @click="setTheme('dark')">
              <IconMoon />
            </BtnIcon>
          </div>
        </Container>
      </div>
      <div class="pointer-events-none relative inset-0 mb-8 md:hidden">
        <template v-if="!data.hero_video">
          <NuxtPicture
            provider="directus"
            :src="props.data.image_day"
            class="h-full w-full object-cover"
            alt=""
            :img-attrs="{
              class: 'h-full w-full object-cover',
            }"
          />
          <div
            class="pointer-events-none absolute inset-0 transition-opacity duration-1s ease-out"
            :class="theme === 'dark' ? 'opacity-100' : 'opacity-0'"
          >
            <NuxtPicture
              provider="directus"
              :src="props.data.image_night"
              alt=""
              :img-attrs="{
                class: 'h-full w-full object-cover',
              }"
            />
          </div>
        </template>
      </div>
    </BlockBase>
    <section class="bg-starline-secondary">
      <div class="relative z-10 w-full pb-10rem">
        <Container class="relative z-10 flex flex-col items-center justify-center">
          <p class="mb-4rem max-w-57rem text-center textNormal">
            {{ dt(props.data.translations)?.intro_text }}
          </p>
          <BtnPrimary
            v-if="dt(props.data.translations)?.cta_button_link
              && dt(props.data.translations)?.cta_button_text"
            :link="dt(props.data.translations)?.cta_button_link ?? ''"
            :text="dt(props.data.translations)?.cta_button_text ?? ''"
          />
        </Container>
        <!-- <BlockBase as="div" class="gradient-top-to-bottom pointer-events-none absolute z-0 h-40rem w-full -top-30rem" /> -->
      </div>
    </section>
  </div>
</template>

<style scoped>
.hero-gradient {
  background: linear-gradient(180deg, #000b1a 0%, rgba(0, 11, 26, 0) 32.29%),
    linear-gradient(0deg, #000b1a 1.88%, rgba(0, 11, 26, 0) 29.15%);
}
.gradient-left-to-right {
  background: linear-gradient(to right, #000710 50%, transparent 100%);
}

.gradient-left-to-right-light {
  background: linear-gradient(to right, #ffffff 50%, transparent 100%);
}

.gradient-right-to-left {
  background: linear-gradient(to left, #000710 50%, transparent 100%);
}

.gradient-right-to-light {
  background: linear-gradient(to left, #ffffff 50%, transparent 100%);
}

.gradient-down {
  background-image: linear-gradient(to bottom, transparent 0%, #000b1a 100%);
}
</style>
